<template>
	<span>
		<button :class="buttonclass" :type="buttontype" @click="click">
			{{label}}&nbsp;
			<fa-icon icon="spinner" spin v-show="isprocessing || isprocessing2" />
		</button>
	</span>
</template>
<script>
	export default {
		name: 'processingbutton',

		props: {
			buttonclass: {
				type: String,
				required: true
			},
			buttontype: {
				type: String,
				default: 'button'
			},
			label: {
				type: String,
				required: true
			},
			isprocessing: {
				type: Boolean,
				default: false
			},
			onclick: {
				type: Function,
				required: true
			},
		},

		data() {
			return {
				isprocessing2: false,
			}
		},

		methods: {
			async click() {
				const that = this
				try {
					that.isprocessing2 = true
					await that.onclick()
				} finally {
					that.isprocessing2 = false
				}
			},
		}
	}
</script>