<template>
    <div class="auth-page join-with-code">
		<div class="content">
			<div class="header">
				<div class="logo">
				<img src="../assets/images/logo.png" alt="logo" />
				</div>
				<div class="right-menu">
          			<router-link to="/login">SIGN IN &nbsp;&nbsp;|&nbsp;&nbsp;</router-link>
          			<router-link to="/register">SIGN UP &nbsp;&nbsp;|&nbsp;&nbsp;</router-link>
					<a href="/howto/" target="_blank">HELP&nbsp;&nbsp;</a>
				</div>
			</div>
			<div class="data-container">
				<div class="left-content">
					<div class="login-text">
						<h3>
							<span>A private virtual Room</span> <span>to play with </span>
							<span> family &#38; friends</span>
						</h3>
						<p>
							<span>See</span> | <span>Talk</span> | <span>Play</span> |
							<span>Feel</span>
						</p>
					</div>
					<div class="join-code-form">
						<template v-if="!isOTPValid">
						<input type="text"
							class="input-box"
							v-model.trim="otp"
							@keyup.enter="join"
							placeholder="Enter Invite Code"
							name="otp"/>
						<processingbutton buttonclass="gold-button" label="Join the Game" :onclick="validateOTP" :isprocessing="isprocessing" />
						</template>
						<template v-else>
							<input type="text"
							class="input-box"
							v-model.trim="email"
							@keyup.enter="join"
							placeholder="Confirm eMail"
							name="username"/>
						<processingbutton buttonclass="gold-button" label="Let's start" :onclick="join" :isprocessing="isprocessing" />

						</template>
					</div>
					<div class="error" v-if="errorMsg">
						<p>
							<img src="../assets/images/icon-error.svg" /> {{errorMsg}}
						</p>
					</div>
					<div class="highlight-text">
						<p>
						<img src="../assets/images/lightbulb.svg" />This is the code sent
						to your eMail/Phone.
						</p>
					</div>
				</div>
			</div>
			<div class="auth-footer">
				<div class="footer-link text-muted font-weight-light">
					<a target="_blank" href="/privacy">Privacy</a> |
					<a target="_blank" href="/terms">Terms of use</a> |
					<a target="_blanK" HREF="/howto">How to</a>
				</div>
				<p>© 2021 ZoomGameZ. All rights reserved.</p>
			</div>
		</div>
	</div>
</template>
<script lang="js">
	import { mapState } from 'vuex'
	import DEBUG from '@/common/DEBUG'
	import processingbutton from '@/components/processingbutton'

	export default {
		name: 'login',

		components: {
			processingbutton
		},

		data() {
			return {
				email: '',
				otp: '',
				isOTPValid: false,
				errorMsg: null,
			}
		},

		computed: {
			...mapState(
				['isprocessing', 'haserror', 'error']
			),
		},

		async mounted() {
			const that = this;
			const query = that.$route.query;
			that.otp = query.OTP;
			that.email = query.email;

			if (that.otp && that.email) {
				await that.join();
			}
		},

		methods: {
			async validateOTP() {
				const that = this
				that.errorMsg = null
				const OTP = that.otp

				if (!OTP) {
					that.errorMsg = 'invalid otp specified'
					return
				}

				try {	
					const gameInfo = await that.$store.dispatch('isValidGameOTP', { OTP })
					this.isOTPValid = true
				}
				catch (error) {
					DEBUG.log('ERROR in join with OTP - %o', error)
					that.errorMsg = error.message
					//that.notifyError(`Unable to join to game using the OTP due to an error - ${error.message}`)
				}
			},
			async join() {
				const that = this
				that.errorMsg = null
				const email = that.email
				const OTP = that.otp

				if (!email || !OTP) {
					that.errorMsg = 'invalid email or otp specified'
					return
				}

				try {
					const gameInfo = await that.$store.dispatch('joinWithGameOTP', { email, OTP })
					await that.$router.push({ name: 'play', params: { id: gameInfo.gameId } })
				}
				catch (error) {
					DEBUG.log('ERROR in join with OTP - %o', error)
					that.errorMsg = error.message
					that.notifyError(`Unable to join to game using the OTP due to an error - ${error.message}`)
				}
			},
		}
	}
</script>